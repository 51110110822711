'use client'

import { Alert, Button } from '@mantine/core'
import { useParams, useRouter } from 'next/navigation'
import { useTransition } from 'react'
import { Icon } from '~/components/icons/icon'
import { countries } from '~/data/countries'
import { defaultCountry, type SupportedCountries } from '~/data/enums'
type ErrorProps = {
  error: Error & { digest?: string }
  reset: () => void
}
export default function Error({ error, reset }: ErrorProps) {
  const [transition, startTransition] = useTransition()
  const router = useRouter()
  const params = useParams<{ country: SupportedCountries }>()
  console.error(error)
  return (
    <Alert
      color="red"
      radius="lg"
      classNames={{ message: 'text-red-800', icon: 'text-red-800' }}
      icon={<Icon name="alert-triangle" />}
      className="flex max-w-full flex-col flex-wrap gap-12 border bg-red-50 leading-tight md:max-w-md md:justify-start"
    >
      <p className="text-pretty">
        We ran into an issue loading the cities for this country
        <strong>({countries[params.country || defaultCountry].name})</strong>.
        Our team is looking into it. Please try again using the button below, or
        come back later if the problem continues.
      </p>

      <div className="mt-6 flex justify-end">
        <Button
          size="xs"
          color="red"
          classNames={{ root: 'bg-red-800 hover:bg-red-900 text-red-50' }}
          loading={transition}
          onClick={() => {
            startTransition(() => {
              console.error(error)
              router.refresh()
              reset()
            })
          }}
        >
          Try again
        </Button>
      </div>
    </Alert>
  )
}
