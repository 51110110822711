import HeroBurundiImage1 from '../../public/images/hero/hero-burundi-1.jpg'
import HeroBurundiImage2 from '../../public/images/hero/hero-burundi-2.jpg'
import HeroBurundiImage3 from '../../public/images/hero/hero-burundi-3.jpg'
import HeroBurundiImage4 from '../../public/images/hero/hero-burundi-4.jpg'
import Mukeke from '../../public/images/hero/mukeke.jpg'
import Royal from '../../public/images/hero/royal.jpg'

import HeroCameroonImage1 from '../../public/images/hero/hero-cameroon-1.jpg'
import HeroCameroonImage2 from '../../public/images/hero/hero-cameroon-2.jpg'
import HeroCameroonImage3 from '../../public/images/hero/hero-cameroon-3.jpg'
import HeroCameroonImage4 from '../../public/images/hero/hero-cameroon-4.jpg'
import KadjiBeer from '../../public/images/hero/kadji-beer.jpg'
import Ndole from '../../public/images/hero/Ndole.jpg'

export const countries = {
  burundi: {
    slug: 'burundi',
    name: 'Burundi',
    flag: 'burundi-flag' as const, // reference the icon name
    coordinates: { lat: -3.373056, lng: 29.918886 },
    bestKnown: {
      cities: ['Bujumbura', 'Gitega'],
      dish: {
        name: 'Mukeke Fish',
        tagline: 'Experience the difference',
        image: Mukeke,
        description:
          "Visiting Burundi? Dive into a taste you won't find anywhere else! Sample the legendary Mukeke, a succulent fish from the depths of Lake Tanganyika. Imagine flaky, white flesh perfectly seasoned and crisped to perfection. Burundian chefs often prepare Mukeke simply, letting its unique flavor shine. Pair it with local greens or fried plantains for an unforgettable Burundi experience!"
      },
      drink: {
        name: 'Royale Beer',
        tagline: 'Refreshment fit for Royalty',
        image: Royal,
        description:
          "Unveiling Burundi: quench your thirst with a Royale Amstel, Burundi's crowned jewel. Brewed with meticulous care, this award-winning lager boasts a refreshing golden hue and a light, crisp taste. Indulge in its delicate balance of sweetness, subtle bitterness, and a hint of fruitiness. Royale Amstel - a perfect toast to your Burundian adventure!"
      }
    },
    images: [
      HeroBurundiImage1,
      HeroBurundiImage2,
      HeroBurundiImage3,
      HeroBurundiImage4
    ]
  },
  cameroon: {
    slug: 'cameroon',
    name: 'Cameroon',
    flag: 'cameroon-flag' as const, // reference the cameroonian flag
    coordinates: { lat: 7.369722, lng: 12.354722 },
    bestKnown: {
      cities: ['Yaoundé', 'Douala'],
      dish: {
        name: 'Ndole',
        tagline: 'The unforgettable taste of Cameroon',
        image: Ndole,
        description:
          "Immerse yourself in the heart of Cameroon with Ndole, the nation's beloved dish. This aromatic stew blends earthy bitter leaves with creamy peanut butter, creating a unique and flavorful experience. Traditionally served with plantains or miondo, Ndole is a must-try for any adventurous foodie. Don't miss out on this taste of Cameroonian culture!"
      },
      drink: {
        name: 'Kadji Beer',
        tagline: 'Raise a glass to adventure',
        image: KadjiBeer,
        description:
          'Unwind with a taste of Cameroon! Kadji Beer, a local legend, offers a refreshingly smooth and balanced experience. Made with 100% malt and no cereals, it boasts a unique, crisp flavor that perfectly complements the vibrant culture of the country. Raise a glass to adventure and discover why Kadji Beer is a must-try for any visitor to Cameroon.'
      }
    },
    images: [
      HeroCameroonImage1,
      HeroCameroonImage2,
      HeroCameroonImage3,
      HeroCameroonImage4
    ]
  }
} as const
