export const supportedCountries = ['cameroon', 'burundi'] as const
export type SupportedCountries = (typeof supportedCountries)[number]
export const defaultCountry = 'cameroon' as const
/**
 * Landing page sliding box content options
 */
export const box = ['dish', 'drink'] as const
export const supportedCurrencies = ['BIF', 'USD', 'KES', 'XAF'] as const
export type SupportedCurrencies = (typeof supportedCurrencies)[number]
export const defaultCurrency = 'USD' as const
export const supportedSources = ['LEAPA', 'SOURCE']
export const defaultSource = 'LEAPA'

export const fiveMins = 300 as const
export const tenMins = 600 as const
export const fifteenMins = 900 as const
export const thirtyMins = 1800 as const
export const oneHour = 3600 as const

export const views = ['map', 'list'] as const
